import { COUNTRY_IMAGE_URLS } from "@/stores/countryImages";
import placeholderFlag from "@/assets/images/countries/flag-placeholder.jpg";

export const getBaseStrapiUrl = () => {
  if (document.URL.startsWith("http://localhost")) {
    return "http://localhost:1337";
  } else if (document.URL.startsWith("https://aipolicy.ius.uzh.ch")) {
    return "https://aipolicy-backend.ius.uzh.ch";
  }
};
export const getStrapiAdminUrl = () => {
  return getBaseStrapiUrl() + "/admin";
};

export const isProduction = () => {
  return document.URL.startsWith("https://aipolicy.ius.uzh.ch");
};

export const BREAKPOINTS = {
  // same values as in variables.scss
  small: 768,
  large: 1400,
};

export const getUrlByCountryCode = (
  countryCode: string,
  addPlaceholder = false,
) => {
  let url = COUNTRY_IMAGE_URLS[countryCode as keyof typeof COUNTRY_IMAGE_URLS];
  if (!url && addPlaceholder) {
    url = placeholderFlag;
  }
  return url;
};
